import { Component, OnInit } from '@angular/core';

@Component({
 	selector: 'app-student-help',
  	templateUrl: './help.component.html',
  	styleUrls: ['./help.component.scss']
})
export class StudentHelpComponent implements OnInit {

  	constructor() { }

  	ngOnInit() {
  	}

}
