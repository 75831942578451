import { Component, OnInit } from '@angular/core';

@Component({
  	selector: 'app-faculty-dashboard',
  	templateUrl: './dashboard.component.html',
  	styleUrls: ['./dashboard.component.scss']
})
export class FacultyDashboardComponent implements OnInit {

  	constructor() { }

  	ngOnInit() {}

}
