import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'appPageNotFound',
	templateUrl: './pageNotFound.component.html',
	styleUrls: ['./pageNotFound.component.scss']
})
export class PageNotFoundComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
