import { Component, OnInit } from '@angular/core';

@Component({
  	selector: 'app-student-feedback',
  	templateUrl: './feedback.component.html',
  	styleUrls: ['./feedback.component.scss']
})
export class StudentFeedbackComponent implements OnInit {

  	constructor() { }

	ngOnInit() {
	}

}
